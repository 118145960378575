import * as React from "react"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import styled from "styled-components"
import CourseTab from "../CourseTab/index"
import BlogsTab from "./BlogsTab"
import Certificate from "./Certificate"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Wrapper>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            <Tab label="course" {...a11yProps(0)} />
            <Tab label="blogs" {...a11yProps(1)} />
            <Tab label="certificate" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <CourseTab
            order={props.order}
            userId={props.userId}
            orderLoading={props.orderLoading}
            coursesReduced={props.coursesReduced}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {props.order?.length ? (
            <BlogsTab />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "500px",
              }}
            >
              <span className="no_course">
                Looks like you haven't saved any blogs
              </span>
            </div>
          )}
        </TabPanel>

        <TabPanel value={value} index={2}>
          {props.order?.length ? (
            <Certificate
              order={props.order}
              userId={props.userId}
              userProfile={props.userProfile}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "500px",
              }}
            >
              <span className="no_course">
                Looks like you haven't enrolled for anycourse
              </span>
            </div>
          )}
        </TabPanel>
      </Box>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  .MuiTab-root.Mui-selected {
    color: var(--purpleColor);
    border-bottom: 1px solid var(--purpleColor) !important;
  }
  button {
    font-size: 18px;
    font-weight: 600;
    padding: 12px 1px !important;
    margin: 0 16px;
    text-transform: lowercase;
    @media (max-width: 479px) {
      padding: 12px 10px !important;
      margin: 0;
    }
  }
  .no_course {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    /* identical to box height */
    letter-spacing: 0.02em;

    color: #000000;
  }
  .MuiTabs-indicator {
    background-color: var(--purpleColor) !important;
    left: 4px !important;
    width: auto;
    display: none;
  }
  .MuiBox-root {
    border-bottom: 0;
    padding: 14px 0;

    @media (max-width: 479px) {
      padding: 0;
      padding-top: 12px;
    }
  }
`
