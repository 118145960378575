import React from "react"
import styled from "styled-components"
import FormControl from "@material-ui/core/FormControl"
import NativeSelect from "@material-ui/core/NativeSelect"
import { useStaticQuery, graphql } from "gatsby"
import StarRatings from "react-star-ratings"
import Carousel from "react-grid-carousel"
import { Skeleton } from "@mui/material"
import { Link } from "gatsby"
import { useGetFreeCourseQuery } from "./features/api/authApi"

function FreeCourse({ type }) {
  const [resIndex, setRes] = React.useState(0)
  const { data: result, isLoading: loading } = useGetFreeCourseQuery()
  console.log("result", result)
  const data = useStaticQuery(graphql`
    {
      allStrapiAuthor {
        nodes {
          id
          strapiId
          name
        }
      }
    }
  `)
  const [state, setState] = React.useState({
    course: "ethical hacking",
  })
  React.useEffect(() => {
    const getIndex = result && result?.findIndex((r) => r.type === state.course)
    const getResult =
      result &&
      result[getIndex]?.type === state.course &&
      result[getIndex].addCourses.findIndex((b) => b.business_category === type)
    setRes(getResult)
  }, [])

  const handleChange = (event) => {
    const name = event.target.name

    setState({
      ...state,
      [name]: event.target.value,
    })
  }
  return (
    <Wrapper>
      <div className="grid_carousel">
        <SelectDay>
          <NativeSelection
            value={state.course}
            name="course"
            onChange={handleChange}
            inputProps={{ "aria-label": "course" }}
          >
            {result &&
              result.map((d) => <option value={d.type}>{d.type}</option>)}
          </NativeSelection>
        </SelectDay>
        {result &&
          result.map(
            (r, id) =>
              r.type === state.course &&
              r.addCourses.map((res, id) => {
                return (
                  <>
                    {res.business_category === type && (
                      <Carousel cols={1} rows={1} gap={20} loop>
                        {res.courses_lms.map((cl, idi) => {
                          return (
                            <Carousel.Item>
                              {!loading ? (
                                <Link to={`/demo-course/${cl.slug}/${cl.id}`}>
                                  <div
                                    className="course_tab_wrapper"
                                    key={cl.id}
                                  >
                                    <div className="course_image">
                                      <img src={cl.course_image.url} alt="" />
                                    </div>
                                    <div className="course_desc">
                                      <h2>{cl.title}</h2>
                                      <h4>
                                        {data.allStrapiAuthor.nodes.map(
                                          (d) =>
                                            d.strapiId === cl.authors[0] &&
                                            d.name
                                        )}
                                      </h4>
                                      <div className="course_rating">
                                        <StarRatings
                                          rating={cl.rating}
                                          starDimension="17.42px"
                                          starSpacing="3px"
                                          starRatedColor="#FFD400"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              ) : (
                                <div className="course_tab_wrapper">
                                  <Skeleton
                                    variant="rectangular"
                                    height={150}
                                  />
                                  <Skeleton width="60%" />
                                </div>
                              )}
                            </Carousel.Item>
                          )
                        })}
                      </Carousel>
                    )}
                    {resIndex === -1 && (
                      <h2
                        style={{
                          fontSize: "1rem",
                          textTransform: "capitalize",
                          fontStyle: "italic",
                        }}
                      >
                        {type} {state.course} courses are coming soon.{" "}
                      </h2>
                    )}
                  </>
                )
              })
          )}
      </div>
      <div className="grid_carousel_1_wrapper">
        <SelectDay>
          <NativeSelection
            value={state.course}
            name="course"
            onChange={handleChange}
            inputProps={{ "aria-label": "course" }}
          >
            {result &&
              result.map((d) => <option value={d.type}>{d.type}</option>)}
          </NativeSelection>
        </SelectDay>
        <div className="grid_carousel_1">
          {result &&
            result.map((r) =>
              r.addCourses.map((res) => {
                return (
                  <>
                    {r.type === state.course &&
                      res.business_category === type &&
                      res.courses_lms.map((cl) =>
                        !loading ? (
                          <Link to={`/demo-course/${cl.slug}/${cl.id}`}>
                            <div className="course_tab_wrapper" key={cl.id}>
                              <div className="course_image">
                                <img src={cl.course_image.url} alt="" />
                              </div>
                              <div className="course_desc">
                                <h2>{cl.title}</h2>
                                <h4>
                                  {data.allStrapiAuthor.nodes.map(
                                    (d) =>
                                      d.strapiId === cl.authors[0] && d.name
                                  )}
                                </h4>
                                <div className="course_rating">
                                  <StarRatings
                                    rating={cl.rating}
                                    starDimension="17.42px"
                                    starSpacing="3px"
                                    starRatedColor="#FFD400"
                                  />
                                </div>
                              </div>
                            </div>
                          </Link>
                        ) : (
                          <div className="course_tab_wrapper">
                            <Skeleton variant="rectangular" height={150} />
                            <Skeleton width="60%" />
                          </div>
                        )
                      )}
                  </>
                )
              })
            )}
          {resIndex === -1 && (
            <div>
              <h2
                style={{
                  fontSize: "1rem",
                  textTransform: "capitalize",
                  fontStyle: "italic",
                }}
              >
                {type} {state.course} courses are coming soon.{" "}
              </h2>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default FreeCourse

const Wrapper = styled.div`
  width: 100%;
  height: 500px;
  overflow-y: auto;
  @media (max-width: 479px) {
    height: auto;
    min-height: 300px;
  }
  a {
    text-decoration: none;
    outline: none;
  }
  .MuiFormControl-root {
    display: block;
    margin-bottom: 2%;
    margin-left: 31px;
    @media (max-width: 479px) {
      margin-bottom: 5%;
      margin-left: 0;
    }
  }
  .MuiNativeSelect-select {
    padding: 10px 12px;
  }
  .MuiInputBase-input {
    font-family: var(family);
    font-weight: 500;
    font-size: 1rem;
  }

  .grid_carousel {
    display: block;
    width: 100%;

    @media (min-width: 479px) {
      display: none;
    }
  }
  .grid_carousel_1_wrapper {
    display: flex;
    flex-direction: column;
    @media (max-width: 479px) {
      display: none;
    }
  }
  .grid_carousel_1 {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    @media (max-width: 479px) {
      display: none;
    }
    .course_tab_wrapper {
      width: 300px;
      margin-left: 30px;
    }
    img {
      border-radius: 6px;
    }
    a {
      margin-bottom: 15px;
    }
  }

  .course_tab_wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    background: #f8f8f8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 9.16513px;
    margin-bottom: 10px;
  }

  .course_image {
    display: flex;
    width: 100%;

    img {
      width: 100%;
      @media (max-width: 479px) {
        border-radius: 6px;
      }
    }
  }
  .course_desc {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 5px 10px;
    h2 {
      margin: 5px 0 !important;
    }
    h2 {
      font-weight: 500;
      font-size: 16px !important;
      letter-spacing: 0.01em;
      text-transform: capitalize !important;
      opacity: 1 !important;
      color: #000000 !important;
      @media (max-width: 479px) {
        font-size: 1rem;
      }
    }
    h4 {
      font-weight: normal;
      font-size: 12px;
      margin: 0 !important;
      /* identical to box height */
      letter-spacing: 0.01em;

      color: #000000;
    }
    .course_rating {
      display: flex;
      justify-content: flex-end;
    }
  }
`
const SelectDay = styled(FormControl)``
const NativeSelection = styled(NativeSelect)``
