import * as React from "react"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import styled from "styled-components"
import Courses from "../Dashboard/CourseTab"
import FreeCourse from "../FreeCourse"
import Bonus from "../Bonus"


function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Wrapper>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            <Tab label="free" {...a11yProps(0)} />
            <Tab label="demo" {...a11yProps(1)} />
            <Tab label="paid" {...a11yProps(2)} />
            <Tab label="bonus" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <FreeCourse type="free" />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FreeCourse type="demo" />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Courses
            order={props.order}
            userId={props.userId}
            orderLoading={props.orderLoading}
            coursesReduced={props.coursesReduced}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Bonus />
        </TabPanel>
      </Box>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: -35px;
`
