import React from "react"
import styled from "styled-components"
import StarRatings from "react-star-ratings"
import Carousel from "react-grid-carousel"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { Skeleton } from "@mui/material"
import noimage from "../../images/noimage.png"

function CourseTab({ order, userId, orderLoading, coursesReduced }) {
  console.log(coursesReduced)
  const data = useStaticQuery(graphql`
    {
      allStrapiAuthor {
        nodes {
          id
          strapiId
          name
        }
      }
    }
  `)
  return (
    <Wrapper>
      <div className="grid_carousel">
        {order?.length ? (
          <Carousel cols={1} rows={1} gap={20} loop>
            {order?.map(
              (o) =>
                userId &&
                userId === o.user._id &&
                coursesReduced.map((c) => {
                  return (
                    <Carousel.Item>
                      {!orderLoading ? (
                        <Link to={`/course/${c.slug}/${o.id}`}>
                          <div className="course_tab_wrapper" key={o.id}>
                            <div className="course_image">
                              <img src={c.course_image ? c.course_image.url : noimage} alt={c.title} />
                            </div>
                            <div className="course_desc">
                              <h2>{c.title}</h2>
                              <h4>
                                {data.allStrapiAuthor.nodes.map(
                                  (d) => d.strapiId === c.author && d.name
                                )}
                              </h4>
                              <div className="course_rating">
                                <StarRatings
                                  rating={c.rating}
                                  starDimension="17.42px"
                                  starSpacing="3px"
                                  starRatedColor="#FFD400"
                                />
                              </div>
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <div className="course_tab_wrapper">
                          <Skeleton variant="rectangular" height={150} />
                          <Skeleton width="60%" />
                        </div>
                      )}
                    </Carousel.Item>
                  )
                })
            )}
          </Carousel>
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                fontSize: "1rem",
                textTransform: "capitalize",
                fontStyle: "italic",
              }}
            >
              No paid course found.
            </h2>
          </div>
        )}
      </div>
      <div className="grid_carousel_1">
        {order?.length ? (
          order?.map(
            (o) =>
              userId === o.user._id &&
              coursesReduced.map((c) => {
                return (
                  <>
                    {!orderLoading ? (
                      <Link to={`/course/${c.slug}/${o.id}`}>
                        <div className="course_tab_wrapper" key={o.id}>
                          <div className="course_image">
                            <img src={c.course_image ? c.course_image.url: noimage} alt="" />
                          </div>
                          <div className="course_desc">
                            <h2>{c.title}</h2>
                            <h4>
                              {data.allStrapiAuthor.nodes.map(
                                (d) => d.strapiId === c.author && d.name
                              )}
                            </h4>
                            <div className="course_rating">
                              <StarRatings
                                rating={c.rating}
                                starDimension="17.42px"
                                starSpacing="3px"
                                starRatedColor="#FFD400"
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <div className="course_tab_wrapper">
                        <Skeleton variant="rectangular" height={150} />
                        <Skeleton width="60%" />
                      </div>
                    )}
                  </>
                )
              })
          )
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                fontSize: "1rem",
                textTransform: "capitalize",
                fontStyle: "italic",
              }}
            >
              No paid course found.
            </h2>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default CourseTab

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 90vh;
  overflow-y: auto;
  flex-direction: column;
  @media (max-width: 479px) {
    height: auto;
    min-height: 300px;
  }
  a {
    text-decoration: none;
    outline: none;
  }

  .grid_carousel {
    display: block;
    width: 100%;

    @media (min-width: 479px) {
      display: none;
    }
  }
  .grid_carousel_1 {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    @media (max-width: 479px) {
      display: none;
    }
    .course_tab_wrapper {
      width: 300px;
      margin-left: 30px;
    }
    img {
      border-radius: 6px;
    }
    a {
      margin-bottom: 15px;
    }
  }

  .course_tab_wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    background: #f8f8f8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 9.16513px;
    margin-bottom: 10px;
  }

  .course_image {
    display: flex;
    width: 100%;

    img {
      width: 100%;
      @media (max-width: 479px) {
        border-radius: 6px;
      }
    }
  }
  .course_desc {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 5px 10px;
    h2 {
      margin: 5px 0 !important;
    }
    h2 {
      font-weight: 500;
      font-size: 16px !important;
      letter-spacing: 0.01em;
      text-transform: capitalize !important;
      opacity: 1 !important;
      color: #000000 !important;
      @media (max-width: 479px) {
        font-size: 1rem;
      }
    }
    h4 {
      font-weight: normal;
      font-size: 12px;
      margin: 0 !important;
      /* identical to box height */
      letter-spacing: 0.01em;

      color: #000000;
    }
    .course_rating {
      display: flex;
      justify-content: flex-end;
    }
  }
`
