import React from "react"
import styled from "styled-components"
import Carousel from "react-grid-carousel"
import { Link } from "gatsby"
import { FiDownload } from "@react-icons/all-files/fi/FiDownload"
import { PDFDocument, StandardFonts, rgb } from "pdf-lib"
import somepdf from "../../images/randomcert.pdf"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import fontkit from "@pdf-lib/fontkit"
import customFont from "../CertificateFonts/Spartan-SemiBold.ttf"
import { saveAs } from "file-saver"
import Progressbar from "../ProgressBar"
import { useSelector } from "react-redux"
import noimage from "../../images/noimage.png"
import {getTopic} from "../../../utils/cart"


function Certificate({ order, userId, userProfile }) {
  console.log(userProfile)
  const [isOpen, setOpen] = React.useState(false)

  const onOpenModal = () => {
    setOpen(!isOpen)
  }

  const topic = getTopic()

  //generate pdf
  const generatePDF = async (name) => {
    const exBytes = await fetch(somepdf).then((res) => {
      return res.arrayBuffer()
    })
    const exFont = await fetch(customFont).then((res) => {
      return res.arrayBuffer()
    })

    const pdfDoc = await PDFDocument.load(exBytes)
    pdfDoc.registerFontkit(fontkit)
    const myFont = await pdfDoc.embedFont(exFont)

    const pages = pdfDoc.getPages()
    const firstPg = pages[0]
    const textSize = 54.5
    const textWidth = myFont.widthOfTextAtSize(name, textSize)
    const getPageWidth = pages[0].getWidth()
    const alignText = Math.abs(getPageWidth / 2 - textWidth / 2)

    firstPg.drawText(name, {
      x: alignText,
      y: 300,
      size: 54.5,
      font: myFont,
      color: rgb(1, 1, 1),
    })

    const uri = await pdfDoc.saveAsBase64({ dataUri: true })
    const pdfBytes = await pdfDoc.save()
    console.log("Done creating")

    // this was for creating uri and showing in iframe

    // const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
    // document.getElementById("pdf").src = pdfDataUri;

    var file = new File([pdfBytes], "certisured.pdf", {
      type: "application/pdf;charset=utf-8",
    })
    saveAs(file)

    setOpen(true)
  }

  //generate pdf

  return (
    <Wrapper>
      <div className="grid_carousel">
        {topic && Object.keys(topic).length ? (
          <Carousel cols={1} rows={1} gap={20} loop>
            {Object.keys(topic).map(
              (key) =>
                topic[key].category === "free" && (
                  <Carousel.Item>
                    <div className="course_tab_wrapper" key={topic[key].keyId}>
                      <div className="course_image">
                        <img
                          src={topic[key].img ? topic[key].img.url : noimage}
                          alt={topic[key].mainTitle}
                        />
                      </div>
                      <div className="course_desc">
                        <h2 style={{ fontWeight: "400", paddingRight: "15px" }}>
                          {topic[key].mainTitle}
                        </h2>
                        {topic[key].courseFinishedLength ===
                          topic[key].courseLength && (
                          <FiDownload
                            onClick={() =>
                              generatePDF(
                                `${userProfile[0]?.userFirstName} ${userProfile[0]?.userLastName}`
                              )
                            }
                          />
                        )}
                      </div>
                      <div className="certificate_progress_bar">
                        {topic[key].courseFinishedLength ===
                        topic[key].courseLength ? null : (
                          <Progressbar
                            bgcolor="orange"
                            progress={topic[key].courseFinishedLength}
                            maxCompleted={topic[key].courseLength}
                            height={15}
                            span={true}
                          />
                        )}
                      </div>
                    </div>
                  </Carousel.Item>
                )
            )}
          </Carousel>
        ) : (
          <h2
            style={{
              fontSize: "1rem",
              textTransform: "capitalize",
              fontStyle: "italic",
            }}
          >
            No certicates found.
          </h2>
        )}
      </div>
      <div className="grid_carousel_1">
        {Object.keys(topic).length ? (
          Object.keys(topic).map((key) => (
            <>
              {topic[key].category === "free" ? (
                <div className="course_tab_wrapper" key={topic[key].keyId}>
                  <div className="course_image">
                    <img
                      src={topic[key].img ? topic[key].img.url : noimage}
                      alt={topic[key].mainTitle}
                    />
                  </div>
                  <div className="course_desc">
                    <h2 style={{ fontWeight: "400", paddingRight: "15px" }}>
                      {topic[key].mainTitle}
                    </h2>
                    {topic[key].courseFinishedLength ===
                      topic[key].courseLength && (
                      <FiDownload
                        onClick={() =>
                          generatePDF(
                            `${userProfile[0]?.userFirstName} ${userProfile[0]?.userLastName}`
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="certificate_progress_bar">
                    {topic[key].courseFinishedLength ===
                    topic[key].courseLength ? null : (
                      <Progressbar
                        bgcolor="orange"
                        progress={topic[key].courseFinishedLength}
                        maxCompleted={topic[key].courseLength}
                        height={15}
                        span={true}
                      />
                    )}
                  </div>
                </div>
              ) : null}
            </>
          ))
        ) : (
          <h2
            style={{
              fontSize: "1rem",
              textTransform: "capitalize",
              fontStyle: "italic",
            }}
          >
            No certicates found.
          </h2>
        )}
      </div>
    </Wrapper>
  )
}

export default Certificate

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  .wrapper {
    border: 3px solid blue;
  }

  .container {
    background-color: #fff;
  }

  .barCompleted {
    background-color: lightblue;
    width: auto;
  }

  .label {
    font-size: 20px;
    color: green;
  }
  a {
    text-decoration: none;
    outline: none;
  }

  .grid_carousel {
    display: block;
    width: 100%;

    @media (min-width: 479px) {
      display: none;
    }
  }
  .grid_carousel_1 {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    @media (max-width: 479px) {
      display: none;
    }
    .course_tab_wrapper {
      width: 300px;
      margin-left: 30px;
    }
    img {
      border-radius: 6px;
    }
    a {
      margin-bottom: 15px;
    }
  }

  .course_tab_wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    background: #f8f8f8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 9.16513px;
    margin-bottom: 30px;
  }

  .course_image {
    display: flex;
    width: 100%;

    img {
      width: 100%;
      @media (max-width: 479px) {
        border-radius: 6px;
      }
    }
  }
  .course_desc {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
    min-height: 50px;
    align-items: center;

    svg {
      font-size: 22px;
      color: var(--purpleColor);
      cursor: pointer;
    }
    h2 {
      margin: 5px 0 !important;
    }
    h2 {
      font-weight: 400;
      font-size: 14px !important;
      letter-spacing: 0.01em;
      text-transform: capitalize !important;
      opacity: 1 !important;
      color: #000000 !important;
    }
    h4 {
      font-weight: normal;
      font-size: 12px;
      margin: 0 !important;
      /* identical to box height */
      letter-spacing: 0.01em;

      color: #000000;
    }
    .course_rating {
      display: flex;
      justify-content: flex-end;
    }
  }
`
