import React from "react"
import styled from "styled-components"

function BlogsTab() {
  return (
    <Wrapper>
      <div className="blogs_tab_wrapper">
        <h2
          style={{
            fontSize: "1rem",
            textTransform: "capitalize",
            fontStyle: "italic",
          }}
        >
          No Blogs found.
        </h2>
      </div>
    </Wrapper>
  )
}

export default BlogsTab

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 400px;

  h1 {
    font-weight: 500;
    font-size: 16px !important;
    letter-spacing: 0.01em;
    text-transform: capitalize !important;
    opacity: 1 !important;
    color: #000000 !important;
    @media (max-width: 479px) {
      font-size: 1rem;
    }
  }
`
