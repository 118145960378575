import React from "react"
import styled from "styled-components"

function Bonus() {
  return (
    <Wrapper>
      <div>
        <h2
          style={{
            fontSize: "1rem",
            textTransform: "capitalize",
            fontStyle: "italic",
          }}
        >
          Bonus are unlocked when you buy particular course.
        </h2>
      </div>
    </Wrapper>
  )
}

export default Bonus

const Wrapper = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  @media (max-width: 479px) {
    height: auto;
    min-height: 400px;
  }
`
