import React from "react"
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch"
import styled from "styled-components"
function SearchContainer({handleInputChange}) {
  return (
    <Wrapper>
      <div className="search__container">
        <input
          className="search__input"
          type="text"
          placeholder="search"
          onChange={(event) => handleInputChange(event)}
        />
        <div className="search_icon">
          <AiOutlineSearch />
        </div>
      </div>
    </Wrapper>
  )
}

export default SearchContainer

const Wrapper = styled.div`
  width: 100%;
  .search__container {
    display: flex;
    flex-direction: row;
    background: #f8f8f8;
    border-radius: 5px;
  
    .search__input {
      width: 100%;
      padding: 20px 10px;
      border: none;
      background-color: transparent;
      transition: transform 250ms ease-in-out;
      font-size: 14px;
      line-height: 18px;
      outline: none;
      color: var(--secondaryColor);
      font-family: var(--family);
      backface-visibility: hidden;
    }
    .search_icon {
      display: flex;
      width: 60px;
      background-color: none;
      justify-content: center;
      align-items: center;
      border-radius: 69.3284px;

      svg {
        color: #000;
        font-size: 30px;
      }
    }
    .search__input::placeholder {
      color: rgba(87, 87, 86, 0.8) !important;
      text-transform: lowercase;
      letter-spacing: 1.5px;
    }
  }
`
