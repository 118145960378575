import React from "react"
import styled from "styled-components"
import Analogo from "../images/favicon.svg"
import CustomTabs from "../components/Dashboard/CustomTabs"
import { Link } from "gatsby"
import loadingg from "../images/loading.gif"
import { AiOutlinePlayCircle } from "@react-icons/all-files/ai/AiOutlinePlayCircle"
import { useSelector } from "react-redux"
import {
  useGetUserDetailsQuery,
  useGetOrdersQuery,
  useGetUserQuery,
  useAddUserMutation,
} from "../components/features/api/authApi"
import AvatarMenu from "../components/Dashboard/AvatarMenu"
import SearchContainer from "../components/Dashboard/SearchContainer"
import noimage from "../images/noimage.png"
import { getTopic, saveDetails, saveNotes, saveTopic } from "../../utils/cart"

function Dashboard() {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  const months = [
    "January",
    "Febraury",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const emptyQuery = ""

  // Convert date and time
  const d = new Date()
  const date = d.getDate()
  const day = days[d.getDay()]
  const month = months[d.getMonth()]
  const year = d.getFullYear()

  const token = useSelector((state) => state.user.token)

  const [addUser, { isLoading }] = useAddUserMutation()

  const {
    data: userDetails,
    isLoading: userDetailsIsLoading,
    // error: userDetailsError,
    // isError: userDetailsIsError,
    // isSuccess: userDetailsIsSuccess,
  } = useGetUserDetailsQuery()
  console.log("userDetails", userDetails)

  const { data: order, isLoading: orderLoading } = useGetOrdersQuery()
  const { data: userProfile } =
    useGetUserQuery()
  console.log("order", order)

  const topic = getTopic()



  React.useEffect(() => {
    const checkUserProfile = async () => {
      if (userProfile && !userProfile.length) {
        await addUser({
          topicDetails: {},
          userFirstName: userDetails.username,
          userLastWatched: {},
          userNotes: [],
          userCart: [],
        })
      }
    }
    checkUserProfile()
  }, [userProfile])

  // state for holding the search result
  const [state, setState] = React.useState({
    filteredData: [],
    filteredTopic: {},
    query: emptyQuery,
  })

  //   only adding courses to array from an order
  const reducedCourse =
    order &&
    order?.reduce((total, amount) => {
      amount.courses.forEach((course) => {
        total.push(course)
      })
      return total
    }, [])

  //input change
  const handleInputChange = (event) => {
    const query = event.target.value
    const courses = reducedCourse || []
    const filteredData = courses.filter((course) => {
      const { title, overview, slug } = course
      return (
        title
          .toLowerCase()
          .includes(
            query.toLowerCase() ||
              overview.toLowerCase().includes(query.toLowerCase())
          ) || slug.toLowerCase().includes(query.toLowerCase())
      )
    })

    //input change for watch history
    const filteredTopic =
      topic &&
      Object.keys(topic)
        .filter((key) => {
          const { title, slug, overview } = topic[key]
          return (
            title
              .toLowerCase()
              .includes(
                query.toLowerCase() ||
                  overview.toLowerCase().includes(query.toLowerCase())
              ) ||
            overview.toLowerCase().includes(query.toLowerCase()) ||
            slug.toLowerCase().includes(query.toLowerCase())
          )
        })
        .reduce((obj, key) => {
          obj[key] = topic[key]
          return obj
        }, {})
    setState({
      query,
      filteredData,
      filteredTopic,
    })
  }

  //assigning the result of search
  const {  query, filteredTopic } = state
  // const hasSearchResults = filteredData && query !== emptyQuery
  const hasTopicResults = filteredTopic && query !== emptyQuery
  const courses = reducedCourse
  const topics = hasTopicResults ? filteredTopic : topic

  console.log(filteredTopic)

  //remove token
  const removeToken = () => {
    console.log("ok")
    sessionStorage.removeItem("token")
    localStorage.removeItem("details")
    localStorage.removeItem("topic")
    localStorage.removeItem("notes")

    setTimeout(() => {
      window.location.href = "/"
    }, 1000)
  }

  console.log("isloading", isLoading)
  console.log("DATA", userProfile)

  return (
    <Wrapper>
      {token && userDetails && topic && (
        <div className="dashboard_wrapper">
          {/* desktop */}

          <div className="dashboard_logo">
            <div>
              <Link to="/">
                <img src={Analogo} alt="certisured logo" />
              </Link>
            </div>
          </div>

          {/* desktop */}

          {/* mobile */}

          <div className="dashboard_logo_mobile">
            <div className="logo_link">
              <Link to="/">
                <img className="logo" src={Analogo} alt="certisured_logo" />
              </Link>
            </div>
            <div class="search-box">
              <SearchContainer handleInputChange={handleInputChange} />
            </div>
            <AvatarMenu
              user={userDetails.username}
              email={userDetails.email}
              removeToken={removeToken}
            />
          </div>

          {/* mobile */}

          {/* tabs */}

          <div className="dashboard_dashboard">
            <h2>Dashboard</h2>
            <h4>
              {day}{" "}
              <span className="date">
                {date} {month} {year}
              </span>
            </h4>
            <h1>
              Hi {userDetails.username},{" "}
              <span className="name">Welcome Back</span>
            </h1>
            <div className="dashboard_tabs">
              <CustomTabs
                order={order}
                userId={userDetails.id}
                userProfile={userProfile}
                orderLoading={orderLoading}
                coursesReduced={courses}
              />
            </div>
          </div>

          {/* tabs */}

          {/* sidebar */}
          <div className="dashboard_sidebar">
            <div className="avatar_searchbar_wrapper">
              <div className="searchbar">
                <SearchContainer handleInputChange={handleInputChange} />
              </div>
              <AvatarMenu
                user={userDetails.username}
                email={userDetails.email}
                removeToken={removeToken}
              />
            </div>
            <div className="about_courses_wrapper">
              <div className="heading">
                <h2>continue studying</h2>
              </div>

              <div className="about_courses">
                {Object.keys(topics).map((key) => (
                  <>
                    <div className="continue_watching_wrapper">
                      <div className="continue_watching">
                        <div
                          className="video_watch"
                          style={{
                            backgroundImage: `url(${
                              topics[key].img ? topics[key].img.url : noimage
                            })`,
                          }}
                        />
                        <div className="icon">
                          <Link
                            to={`${topics[key].uri}/${topics[key].slug}${topics[key].dId}`}
                          >
                            <AiOutlinePlayCircle />
                          </Link>
                        </div>
                      </div>
                      <div className="details">
                        <h3>{topics[key].module}</h3>
                        <h4>{topics[key].title}</h4>
                        <p>{topics[key].overview}</p>
                      </div>
                    </div>
                  </>
                ))}
                {!Object.keys(topics).length && (
                  <h3 style={{ fontWeight: 500 }}>No recent course watched</h3>
                )}
              </div>
            </div>
          </div>
          {/* sidebar */}
        </div>
      )}
      {userDetailsIsLoading ? (
        <div className="no_account">
          <img src={loadingg} alt="" />
        </div>
      ) : !token ? (
        <div className="no_account">
          <h2>You need to Login to see a dasboard</h2>
          <Link className="no_acc_login" to="/login">
            Login
          </Link>
        </div>
      ) : null}
    </Wrapper>
  )
}

export default Dashboard

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: auto;
  width: 100%;

  .avatar_container {
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      margin: 0 auto;
      text-align: center;
    }
    .avatar_img {
      width: 50px !important;
      height: 50px !important;
      border-radius: 50%;
    }
    .menu_container {
      position: absolute;
      right: 0;
      background: #f8f8f8;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      z-index: 1;
      padding: 15px;
      margin-top: 7px;
      top: 122%;
      .user_name {
        display: flex;
        flex-direction: column;

        h2 {
          font-weight: 500;
          font-size: 16px;

          /* identical to box height */
          letter-spacing: 0.01em;
          margin: 0;
          color: #000000;
        }
        h4 {
          font-weight: normal;
          font-size: 12px;
          /* identical to box height */
          letter-spacing: 0.01em;
          margin-top: 0;
          margin-bottom: 10px;
          color: #000000;
        }
      }
      .divider {
        width: 50%;
        height: 2px;
        background: linear-gradient(
          90deg,
          #000000 1.41%,
          rgba(0, 0, 0, 0) 92.18%
        );
      }
      ul {
        padding: 0;

        li {
          font-size: 14px;
          font-weight: 400;
          list-style: none;
          padding: 5px 0;

          a {
            text-decoration: none;
            color: #000000;
          }
          button {
            background: none;
            font-size: 14px;
            padding: 0;
            color: #000;
            cursor: pointer;
          }
          button:hover {
            color: var(--thirdColor);
          }
        }
      }
    }
  }
  .no_account {
    grid-area: auto/1/auto/12;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .no_acc_login {
      background: var(--thirdColor);
      color: var(--secondaryColor);
      font-size: 18px;
      display: flex;
      min-width: 150px;
      text-decoration: none;
      outline: none;
      padding: 15px 25px;
      justify-content: center;
      border-radius: 50px;
      @media (max-width: 479px) {
        font-size: 1rem;
      }
    }
  }

  .dashboard_wrapper {
    grid-area: auto/1/auto/12;
    display: grid;
    grid-template-columns: 100px 2fr 1fr 100px;
    grid-row-gap: 2rem;
    width: 100%;
    height: auto;
    margin-top: 2rem;
    grid-column-gap: 3rem;
    @media (max-width: 991px) {
      grid-template-columns: 60px 2fr 1fr 60px;
      grid-column-gap: 1rem;
      margin-top: 1rem;
      grid-row-gap: unset;
    }

    .dashboard_logo {
      grid-area: auto/1/auto/2;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      @media (max-width: 991px) {
        display: none;
      }
      a {
        text-decoration: none;
        outline: none;
        cursor: pointer;
      }
      img {
        margin-left: 0px;
        width: 70px !important;
        margin-top: 0px;

        @media (max-width: 479px) {
          width: 50px !important;
        }
      }
    }

    .dashboard_logo_mobile {
      display: none;

      .logo {
        width: 50px;
      }
      .logo_link {
        display: flex;
        justify-content: flex-end;

        a {
          outline: none;
          text-decoration: none;
        }
      }
      .search-box {
        width: 100%;
        padding: 10px;
      }
    }
    .dashboard_tabs {
      width: 100%;
      @media (max-width: 479px) {
        margin-top: -20px;
      }
    }

    .dashboard_dashboard {
      grid-area: auto/2/auto/3;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden;
      @media (max-width: 991px) {
        grid-area: auto/1/auto/5;
        margin: 0 16px;
      }
      h2,
      h4,
      span,
      h1 {
        margin: 10px;
        text-transform: capitalize;
      }
      h2 {
        font-weight: bold;
        font-size: 22px; /* identical to box height */
        letter-spacing: 0.02em;
        text-transform: uppercase;

        color: #000000 !important;

        opacity: 0.6;
        @media (max-width: 479px) {
          font-size: 16px;
        }
      }
      h4,
      .date {
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.01em;
        color: #002f54;
        @media (max-width: 479px) {
          font-size: 11.1899px;
          margin-top: 0;
        }
      }
      .date {
        font-weight: 400;
        color: #151515 !important;
        text-transform: capitalize !important;
        opacity: 0.6;
      }
      h1,
      .name {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;

        color: #000000;
        @media (max-width: 479px) {
          font-size: 20px;
          margin-top: 0;
        }
      }
      .name {
        font-weight: 300;
        color: #151515 !important;
        text-transform: capitalize;
        opacity: 0.6;
      }
    }
    .dashboard_sidebar {
      grid-area: auto/3/auto/4;
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;

      @media (max-width: 991px) {
        grid-area: auto/1/auto/5 !important;
      }

      @media (max-width: 1383px) {
        grid-area: auto/3/auto/5;
      }

      .avatar_searchbar_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: 991px) {
          display: none;
        }
        @media (max-width: 1383px) {
          margin-right: 10px;
        }

        .searchbar {
          display: flex;
          width: 80%;
        }
      }
      .about_courses_wrapper {
        background: #f8f8f8;
        margin-top: 50px;
        border-radius: 10px;
        .heading {
          display: flex;
          justify-content: center;
          margin: 30px;
          border-bottom: 1px solid #000;
        }
      }
      .about_courses {
        padding: 0 30px;
        position: sticky;
        top: 2%;
        height: 90vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;

        @media (max-width: 479px) {
          height: 500px;
          padding-top: 0;
          margin-bottom: 50px;
        }
        h2 {
          font-weight: 600;
          font-size: 16px;

          /* identical to box height */

          color: #000000;

          opacity: 0.6;
        }
        h3 {
          @media (max-width: 479px) {
            font-size: 1rem;
          }
        }
        .continue_watching_wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-bottom: 2rem;
          @media (max-width: 991px) {
            width: 80%;
          }
          @media (max-width: 479px) {
            width: 100%;
          }
          .details {
            h3,
            h4 {
              font-weight: bold;
              font-size: 14px; /* identical to box height */
              letter-spacing: 0.02em;
              text-transform: uppercase;

              color: #000000 !important;

              opacity: 0.6;
            }
            h4 {
              font-size: 13px;
              font-weight: 400;
            }
            p {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              font-size: 1rem;
            }
          }
        }
        .continue_watching_wrapper:nth-of-type(1) {
          border: none;
        }
        .continue_watching {
          display: flex;
          width: 100%;
          position: relative;
          .video_watch {
            width: 100%;
            height: 250px;
            background-size: cover;
            background-position: center;
            border-radius: 10px;
            opacity: 0.9;
          }
          .icon {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            svg {
              font-size: 50px;
              color: #fff;
            }
            a {
              text-decoration: none;
              outline: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .dashboard_logo_mobile {
      grid-area: auto/1/auto/5;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      margin: 0 16px;
    }
  }
`
